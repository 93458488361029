import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Urbach: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Urbach,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <polygon
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Urbach"
          points="890,854 897.3,897.8 896,898 860,892 860,900 828.6,907.8 824,894 834,854 854,852 852,840 890,840"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 841.4579 876.6458)"
        >
          Urbach
        </text>
      </g>
    </>
  );
});
